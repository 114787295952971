.adress{
    margin: 20px;
    padding: 15px;
}

.adress li{
    cursor: pointer;
   
}
.adress li:hover{
    color: red;
}