.chkout .card-header {
    padding: 0%;
}

.chkout .card {
    margin: 5px;
}

.chkout .row h1 {
    color: rgb(116, 115, 114);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.chkout .row p {
    color: rgb(145, 144, 143);
    font-size: 18px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.chkout .form-group {
    color: rgb(116, 115, 114);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.borderButton {
    border-bottom: 1px solid;
}

.text-end {
    align-items: end;
}


button:disabled,
button[disabled] {
    background-color: transparent !important;
    color: rgb(129, 129, 129);
}