.cart-popup-action {

    background: #f2f2f2;
    padding: 1rem 1.5rem;
    text-align: center;
    border-radius: 3px;

}

.cart-popup-action .btn {
    display: block;
    margin: 10px 0;
    border: none;
    width: 100%;
    font-size: 13px;
    background-color: #F33333;

}



.btn-checkout {
    padding: 10px;
    background-color: #F33333 !important;
    color: #fff !important;
    font-size: 16px;
    font-weight: 400;
    /* transition: all 0.2s; */
}

.btn-checkout:hover {
    background-color: #495057 !important;

}

.btn-view-cart {
    padding: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.cart-popup-action .cart-popup-imgbottom {
    text-align: center;
    width: 100%;
    margin: 20px 0;

}

.cart-popup-action .cart-popup-imgbottom .previewCartCheckout-price {
    display: block;
    font-size: 28px;
    font-weight: 700;
    padding: 15px 0;
}

.btn.inverse {
    background-color: #495057;
    color: #fff;
}

.btn.inverse:hover {
    background-color: #F33333 !important;

}

.modal-70vw {
    width: 70vw;
    /* Occupy the 70% of the screen width */
    max-width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.priceNQuantity span {
    /* font-size: 18px; */
    color: #F33333;
}