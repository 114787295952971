.CartAccount{
    position: fixed;
    right: 0px;
    top: 40%;
    z-index: 1000;
}

.CartAccount a{
    position: relative;
}

.CartAccount a span{
    display: none;
}
.CartAccount a:hover span{
    display: block;
    z-index: 5000;
    position: absolute;
    right: 100%;
    top: 0;
    width: 50px;
    background-color:#f33333;
    text-align: center;
    height: 40px;
    color: white;
    padding-top: 7px;
    margin-top: -7px;
}

.CartAccount i{
    width: 50px;
    background-color:#f33333;
    text-align: center;
    height: 40px;
    color: white;
    padding-top: 10px;
    margin-bottom: 5px;
}
.shoppingCart{
    display: flex;
    color: white;
}
.shoppingCart h5{
    margin-left: 10px;
}
.ModalTitle{
    background-color: #f33333;
}
.cartitemsLength{
padding: 20px;
border: 1px solid;
margin: 20px;
background-color: #eee;
text-align: center;
}
.imageCart{   
        margin-bottom: 5px;
        border: 1px solid;
        padding: 20px;
        border-radius: 5px;
}
.cartFont {
    font-weight: bold;
    font-size: 24;
}

.cartButton{
    text-align: center;
    margin: 10px;
}
.cartButton button{
    width: 100%;
}
.table td{
    font-weight: bold;
    color: #f33333;
}
.languageAccount  p{
    padding: 10px;
border: 1px solid rgba(0,0,0,.15);
margin-top: 10px;
margin-bottom: 10px;
background-color: #eee;
text-align: center;
border-radius: 5px;
}
.languageAccount{
    margin: 15px;
    padding: 15px;
    border-bottom: 1px solid rgba(0,0,0,.15);
}
.AccountRow{
    text-align: center;
}
.AccountRow a{
    color: black;
}
.AccountRow a:hover{
    color: #eb6b5aed;
}
.form-box{
    margin-bottom: 40px;
}
.languageSearch{
border: 1px solid rgba(0,0,0,.15);
background-color: #eee;
border-radius: 5px;
margin: 10px;
padding: 5px;
}
.form-box input.field, #so-groups .form-content .form-box select.field {
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    -webkit-appearance: none;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 10px 35px 10px 15px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    background-color: #eee;
}
.form-box input + i {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 50%;
    cursor: pointer;
    font-size: 16px;
    margin-top: -50px;

}
#button-search {
    text-align: center;
    background-color:#f33333;
    display: inline-block;
    border: 0px;
    border-radius: 30px;
    padding: 10px 40px;
    color: #fff;
    font-size: 18px;
}