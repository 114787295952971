.catsBar {
    display: flex;
    align-items: center;
}

.cats {
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 20%;
    padding: 20px 0;
    position: relative;
    cursor: pointer;

}

.subCats {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 64px;
    width: 100%;
    z-index: 100;
}

.cats ul {
    list-style-type: none;
    padding-left: 0;
}

.subCats ul {
    position: relative;
}

.subCats li {
    padding: 10px;
    margin: 10px 0;
}

.subCats a {
    /* border: 1px solid red; */
    text-decoration: none;
    color: inherit;
}

.cats .fa {
    color: #fff;
    margin: 0 10px;
    font-size: 16px;
    font-weight: 700;
}

.other-cats {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.other-cat {
    margin: 0 24px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    padding: 20px 20px;
}

.other-cat:hover {
    background-color: rgba(255, 254, 254, 0.815);
}

.subCats>.items li {
    color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.subCats>.items i {
    font-size: 12px;
}

.subCats>.items li:hover {
    background-color: rgba(255, 254, 254, 0.815);
    color: rgb(59, 59, 59);

}

.subCats>.items li:hover i {
    color: rgb(59, 59, 59);

}

.other-cat .fa-chevron-down {
    margin: 0 10px;
}

.other-cat:last-child {
    margin-left: auto !important;
}

.mega-cat {
    position: relative;
}

.mega-subCat {
    position: absolute;
    left: 0;
    top: 100%;
    /* width: 870px; */
    z-index: 20;
    visibility: hidden;
    opacity: 0;

    padding: 20px;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
    box-sizing: content-box !important;
    -moz-box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;

    transition: all 0.8s;
}

.mega-subCat-left {

    position: absolute;
    left: 100%;
    top: -11px;
    /* width: 870px; */
    z-index: 21;
    visibility: hidden;
    opacity: 0;

    padding: 20px;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
    box-sizing: content-box !important;
    -moz-box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;

    transition: all 0.8s;

}

.collect {
    width: 870px;

}

.pages {
    width: 360px;
}

.pages p {
    font-size: 14px;
    color: rgb(109, 109, 109);
    transition: all 0.3s;
}

.pages p:hover {
    color: orangered;
}

.newArrival {
    width: 500px;
}

.newArrival ul {
    list-style-type: none;
    padding-left: 10px;
}

.newArrival ul li {
    display: flex;
    align-items: center;
    transition: all 0.3s;
}

.newArrival ul li:hover {
    color: orangered;
}

.newArrival ul li p {
    margin: 0;
}

.newArrival ul li i {
    font-size: 12px;
    margin-right: 5px;
}

.mega-cat:hover .mega-subCat {
    visibility: visible;
    opacity: 1;
}

.subCats>.items li:hover .mega-subCat-left {
    visibility: visible;
    opacity: 1;
}

.other-cats .other-cat h6 {
    color: rgb(70, 70, 70);
    transition: all 0.3s;
}

.other-cats .other-cat h6:hover {
    color: orangered;
}



.mega-subCat-left h6 {
    color: rgb(70, 70, 70);
    transition: all 0.3s;
}

.mega-subCat-left h6:hover {
    color: orangered;
}

.mega-subCat-left hr {
    color: rgb(70, 70, 70);
}