.singleBannerCardContainer {
    width: 100%;
    margin: 0 20px;
    border-radius: 5px;
    /* border: 1px solid grey; */
    position: relative;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 30px 20px;
}

.banner-title {
    color: #f33333;
    font-weight: 700;
}

.banner-subtitle {
    width: 50%;
    font-weight: 600;
    color: rgb(104, 104, 104);
}

.banner-button {
    border-bottom: 1px solid grey;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s;
    color: rgb(104, 104, 104);


}

.banner-button:hover {
    color: #f33333;
    border-bottom: 1px solid #f33333;
}

.banner-button a {
    text-decoration: none;
    color: inherit;
}