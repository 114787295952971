.bb {
    width: 100%;
    border-bottom: 1px solid rgb(194, 189, 189);
}

.top-header .fa {
    color: #F33333;
    margin-left: 20px;
}

.mainwrap {
    display: flex;
    position: relative;
    align-items: center;

}

.small {
    font-size: 14px;
    color: rgb(87, 80, 80);
    font-weight: 400;
    margin-left: 5px;
}

.wrap {
    visibility: hidden;
    position: absolute;
    left: 10%;
    top: 230%;
    z-index: 300;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    transition: all 0.2s ease-out;
    box-shadow: 0 0px 5px 0px rgb(0 0 0 / 20%);
    cursor: pointer;


}

.mainwrap:hover .wrap {
    visibility: visible;
    position: absolute;
    left: 10%;
    opacity: 1;
    top: 130%;
    background-color: #fff;
    box-shadow: 0 0px 5px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
    width: 100%;
}


.fa-chevron-down {
    color: rgb(70, 65, 65);
    font-size: 10px;
    font-weight: 400;
}

.small-gap {
    width: 15px;
}

.top-header span {
    margin-left: 20px !important;
    font-size: 10px !important;
}

.language {
    outline: none;
    border: 1px solid #f33333a2;
    border-radius: 5px;
    margin-left: 20px;
    padding: 2px 5px;
    color: rgb(87, 87, 87);
}