.collopsBtn{
    text-align: inherit;
    background-color: #d4d4d4;
    border: none;
}
.collopsBtn:hover{
    background-color: #d4d4d4;
    border: none;

}
.getQuotesBtn{
    margin-top: 10px;
}